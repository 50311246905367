<template src="./PrintCertificate.html">
</template>

<script>
    import QrcodeVue from 'qrcode.vue'
    import CustomLoading from "../../components/CustomLoading.vue";
    import CustomSnackbar from "../../components/CustomSnackbar.vue";


    export default {
        name: 'PrintCertificate',

        props: {
            
        },

        components: {
            QrcodeVue,
            CustomLoading,
            CustomSnackbar
        },

        computed: {
            qrInfo() {
                return this.certificateLink
            },
            qrValidate() {
                return this.certificateId
            }
        },

        created() {
            this.getCertificateInfo();
        },

        data: () => ({

            personNotFound: false,
            notFoundMsg: '',
            certificateId: null,
            lastVaccineName: '',
            lastVaccinationDate: null,
            previusVaccineName: '',
            previusVaccinationDate: null,
            certificateInfo: {
                refNo: '',
                fullName: '',
                dateOfBirth: '',
                passportNo: '',
                nationalityEn: '',
                vaccinations: [],
            },

            certificateLink: '',
            size: 150,
            qrValidateSize: 80,
            renderAs: 'svg'
        }),

        mounted() {
        },


        methods: {

            getCertificateInfo() {

                this.$http.getCertificateInfo(this.$route.params.cipherCertificateId)
                    .then(res => {
                        let data = res.data;
                        this.certificateInfo = data.certificateInfo;
                        this.certificateId = this.certificateInfo.cipherCertificateId;

                        let printLink = this.$router.resolve(
                            {
                                name: 'PrintCertificate',
                                params: { cipherCertificateId: this.certificateInfo.cipherCertificateId }
                            }
                        ).href;
                        this.certificateNotFound = false;
                        this.certificateLink = `${window.location.origin}${printLink}`;

                        let index = this.certificateInfo.vaccinations.length;
                        if (index == 1) {
                            this.previusVaccinationDate = this.certificateInfo.vaccinations[index - 1].vaccinationDate;
                            this.previusVaccineName = this.certificateInfo.vaccinations[index - 1].vaccineName;
                        } else if (index > 1) {
                            this.previusVaccinationDate = this.certificateInfo.vaccinations[index - 1].vaccinationDate;
                            this.previusVaccineName = this.certificateInfo.vaccinations[index - 1].vaccineName;
                            this.lastVaccinationDate = this.certificateInfo.vaccinations[index - 1].vaccinationDate;
                            this.lastVaccineName = this.certificateInfo.vaccinations[index - 1].vaccineName;
                        }
                    })
                    .catch(err => {
                        if (err.response.status == 404) {
                            this.personNotFound = true;
                            this.notFoundMsg = err.response.data.msg;
                        }
                        else {
                            this.$store.commit("snackbar/show",
                                { message: err.response.data.msg, icon: "mdi-alert-circle-outline", type: "error" }
                            );
                        }

                    });

            },

            printCertificate() {
                window.open(this.certificateLink);
            }
        }
    }
</script>

<style lang="scss" scoped src="./PrintCertificate.scss" ></style>